<template>
  <div>
    <div class="text-center pt-2 pb-2" v-if="loadingBlueprints">
      <a-spin />
    </div>
    <template v-else>
      <a-select
        allowClear
        show-search
        @change="selectedBlueprint"
        v-model:value="selectedBlueprintValue"
        style="width: 250px"
        placeholder="Select Business Objects"
        v-if="selectedBlueprintId && blueprints?.length"
        :options="selectOptions"
        :filter-option="filterOption"
      >
      </a-select>
      <a-divider />
      <template v-for="copyBlueprint in copyFromBlueprint" :key="copyBlueprint._id">
        <a-card size="small" :title="capitalize(copyBlueprint.name)" style="margin-bottom: 10px">
          <template #extra>
            <a @click="removeOne(copyBlueprint._id)">Delete</a>
          </template>
          <div class="update-fields" v-for="field in copyBlueprint.fields" :key="field._id" style="margin-bottom: 10px">
            <div class="cell input-name">
              <strong>{{ capitalize(field.label) }}</strong>
              <div class="muted">{{ field.structure.type }}</div>
            </div>

            <div class="cell modify-filed" style="text-align: center">
              <a-radio-group
                :value="selectBindType(copyBlueprint._id, field._id)"
                @change="changeBindType(copyBlueprint._id, field._id, $event)"
                button-style="solid"
              >
                <a-radio-button value="from-field">Insert value from</a-radio-button>
                <a-radio-button value="static-value">Static value</a-radio-button>
              </a-radio-group>
            </div>

            <template v-if="selectBindType(copyBlueprint._id, field._id) === 'static-value'">
              <a-input
                :default-value="staticValue(copyBlueprint._id, field._id)"
                @change="changeStaticValue(copyBlueprint._id, field._id, $event)"
                style="margin-top: 5px"
                placeholder="Value"
              />
            </template>

            <template v-else>
              <a-checkbox :checked="getUseHtml(copyBlueprint._id, field._id)" @change="event => setUseHtml(copyBlueprint._id, field._id, event)"
                >Use advanced html</a-checkbox
              >
              <div v-if="!getUseHtml(copyBlueprint._id, field._id)">
                <a-button-group class="mt-1" v-if="showRefFieldPath(copyBlueprint._id, field._id) !== ''">
                  <a-button style="cursor: default" type="primary">
                    {{ showRefFieldPath(copyBlueprint._id, field._id) }}
                  </a-button>
                  <a-button @click="editBindField(copyBlueprint._id, field._id)"><EditOutlined /> </a-button>
                </a-button-group>
                <div
                  class="mt-1"
                  v-if="
                    (bindField[copyBlueprint._id] ?? '') === field._id ||
                    (showRefFieldPath(copyBlueprint._id, field._id) === '' && (bindField[copyBlueprint._id] ?? '') !== field._id)
                  "
                >
                  <ReferenceFields
                    v-if="blueprint"
                    :blueprints="blueprints"
                    :defaultSettings="{
                      optionChangedData: 'CHANGE DATA (history)',
                    }"
                    :selectedBlueprint="{
                      ...(blueprint ?? {}),
                      fields: [...Object.values(fields)],
                    }"
                    :toFieldFromOtherBlueprint="false"
                    :justReferenceFields="false"
                    :justSingleReferenceFields="true"
                    :mainBlueprint="null"
                    :cardView="false"
                    :otherBlueprint="false"
                    @toField="(value, index) => selectField(copyBlueprint._id, field._id, value, index)"
                  />
                </div>
              </div>

              <div v-if="getUseHtml(copyBlueprint._id, field._id)" class="pb-3">
                <a-button @click="openTokenModal(copyBlueprint._id, field._id)" class="mt-1 mb-1" type="primary">
                  {{
                    showRefFieldPath(copyBlueprint._id, field._id, false) === ''
                      ? blueprint?.name
                      : showRefFieldPath(copyBlueprint._id, field._id, false)
                  }}
                </a-button>
                <div>
                  Html template
                  <a-button size="small" class="mb-2" @click="openTokenModal(copyBlueprint._id, field._id)">Use fields tokens</a-button><br />
                </div>
                <Codemirror
                  :model-value="fromHtml(copyBlueprint._id, field._id)"
                  :options="{
                    btabSize: 4,
                    mode: 'text/html',
                    theme: 'base16-dark',
                    lineNumbers: true,
                    line: true,
                  }"
                  :extensions="extensions"
                  @change="value => setFromHtml(copyBlueprint._id, field._id, value)"
                />
              </div>
            </template>
          </div>
        </a-card>
      </template>
      <teleport to="body">
        <a-modal v-model:open="tokenModal.visible" :title="'Tokens'" :footer="null" :width="1000" @cancel="closeTokenModal">
          <a-divider />
          <div v-if="tokenModal.visible">
            <div class="mb-2" v-if="tokenModal?.lastField?.blueprintId !== blueprint._id">
              <h6 v-if="tokenModal.modalName && tokensFields.length">
                {{ tokenModal.modalName }}
              </h6>
              <template v-for="field in tokensFields" :key="field._id">
                <a-button size="small" @click="copyURL(field, field.blueprintName)" style="margin: 0 5px 5px 0">
                  <span
                    style="
                      margin-right: 10px;
                      padding-right: 10px;
                      margin-left: -7px;
                      padding-left: 10px;
                      background-color: #f5f5f5;
                      border-radius: 4px 0 0 4px;
                      height: 22px;
                      border-right: 1px solid #ddd;
                    "
                    >{{ field.structure.type }}</span
                  >
                  {{ generateToken(field, field.blueprintName) }} ({{ field.structure.type }})
                  <CopyOutlined />
                </a-button>
              </template>
            </div>
            <div>
              <h6 v-if="blueprint">
                {{ blueprint.name }}
              </h6>
              <template v-for="field in fields" :key="field._id">
                <a-button size="small" @click="copyURL(field, blueprint?.name)" style="margin: 0 5px 5px 0">
                  <span
                    style="
                      margin-right: 10px;
                      padding-right: 10px;
                      margin-left: -7px;
                      padding-left: 10px;
                      background-color: #f5f5f5;
                      border-radius: 4px 0 0 4px;
                      height: 22px;
                      border-right: 1px solid #ddd;
                    "
                    >{{ field.structure.type }}</span
                  >
                  {{ generateToken(field, blueprint?.name) }}
                  <CopyOutlined />
                </a-button>
              </template>
            </div>
          </div>
        </a-modal>
      </teleport>
    </template>
  </div>
</template>

<script>
import { blueprintApi } from '@dataSystem/api';
import ReferenceFields from '@/apps/templateManagement/views/Builder/components/referenceFieldsComponents/ReferenceFields.vue';
import _ from 'lodash';
import { WorkflowActions } from '@workflow/shared/workflow.store';
import { CopyOutlined, EditOutlined } from '@ant-design/icons-vue';
import { Codemirror } from 'vue-codemirror';
import { javascript } from '@codemirror/lang-javascript';
import { oneDark } from '@codemirror/theme-one-dark';
import { slugify } from '@/core/utils/string-manipulation';
import { message } from 'ant-design-vue';

export default {
  name: 'workflowBuilderCopyDataIndex',
  components: {
    CopyOutlined,
    // FieldWidget,
    ReferenceFields,
    EditOutlined,
    Codemirror,
  },
  props: ['workflows', 'selectedWorkflow', 'event', 'eventList'],
  async mounted() {
    this.extensions = [javascript(), oneDark];
    await this.fetchAllBlueprint();
    //
    await Promise.all(
      this.localEvent.copyData.map(async data => {
        const blueprint = this.blueprints.find(b => b._id.toString() === data.blueprintId.toString());
        if (blueprint && !this.copyFromBlueprint.some(item => item.blueprint._id === blueprint._id)) {
          this.copyFromBlueprint.push({ ...blueprint });
        }
      })
    );
    //
    await this.fetchBlueprint();
    this.findMainBlueprintReferenceFields();
  },
  data() {
    return {
      fields: [],
      blueprint: null,
      selectedBlueprintIdValue: null,
      copyFromBlueprint: [],
      blueprints: [],
      mainBlueprintReferenceFields: {},
      bindField: {},
      referenceFieldsKey: 1,
      localEvent: { ...this.event },
      extensions: null,
      tokenModalVisible: false,
      loadingBlueprints: false,

      selectedBlueprintValue: undefined,

      tokenModal: {
        visible: false,
        blueprintId: null,
        toFieldId: null,
        lastField: null,
        modalName: '',
      },
    };
  },

  computed: {
    selectedBlueprintId() {
      return this.selectedWorkflow.blueprint;
    },
    tokensFields() {
      const blueprint = this.blueprints.find(b => b._id.toString() === this.tokenModal?.lastField?.blueprintId.toString());
      return (blueprint?.fields ?? []).map(item => {
        return { blueprintName: blueprint.name, ...item };
      });
    },
    selectOptions() {
      return this.blueprints
        .filter(b => b._id !== this.selectedBlueprintId)
        .map(b => {
          return {
            value: b._id,
            label: b.name,
          };
        });
    },
  },

  watch: {
    async localEvent(data) {
      await WorkflowActions.editOneEvent(this.selectedWorkflow._id, this.event._id, { ...data });
    },
    selectedBlueprintId() {
      this.fetchBlueprint();
    },
  },
  methods: {
    openTokenModal(blueprintId, toFieldId) {
      this.tokenModal.visible = true;
      this.tokenModal.blueprintId = blueprintId;
      this.tokenModal.toFieldId = toFieldId;
      this.tokenModal.lastField = this.lastCopyFieldValue(blueprintId, toFieldId);
      const modalName = this.showRefFieldPath(blueprintId, toFieldId, false);
      this.tokenModal.modalName = modalName === '' ? this.blueprint.name : modalName;
    },
    closeTokenModal() {
      this.tokenModal.visible = false;
      this.tokenModal.blueprintId = null;
      this.tokenModal.toFieldId = null;
      this.tokenModal.lastField = null;
      this.tokenModal.modalName = '';
    },
    copyURL(field, base = '') {
      navigator.clipboard.writeText(this.generateToken(field, base));
    },
    generateToken(field, base = '') {
      return `{{=token${base !== '' ? `?.${slugify(base)}` : ''}?.${slugify(field.label)}}}`;
    },
    capitalize(value) {
      if (!value) return '';
      return value.toString().charAt(0).toUpperCase() + value.toString().slice(1);
    },
    editBindField(blueprintId, toFieldId) {
      if (this.bindField[blueprintId] === toFieldId) {
        this.bindField = { ...this.bindField, [blueprintId]: null };
      } else {
        this.bindField = { ...this.bindField, [blueprintId]: toFieldId };
      }
    },
    showRefFieldPath(blueprintId, toFieldId, addLastField = true) {
      const data = [...(this.localEvent.copyData.find(item => item.blueprintId === blueprintId)?.fields ?? [])].find(
        item => item.toFieldId === toFieldId
      )?.fromFieldId;
      const fields = [...(data ?? [])];
      const lastField = fields[fields.length - 1];
      if (!addLastField && lastField?.type !== 'reference') {
        fields.pop();
      }
      return fields
        .map(
          item =>
            `${item.fieldName.startsWith('_') ? _.startCase(item.fieldName.replace('_', '')).toUpperCase() : item.fieldName} (${item.fieldName === '_changedData' ? 'history' : item.type})`
        )
        .join(' > ');
    },
    findMainBlueprintReferenceFields() {
      Object.values(this.fields).forEach(field => {
        if ((field?.structure?.elementStructure?.type ?? field?.structure?.type) === 'reference') {
          const refBlueprintId = field?.structure?.elementStructure?.ruleset?.blueprintId ?? field.structure.ruleset.blueprintId;
          const reBlueprint = this.blueprints.find(item => item._id.toString() === refBlueprintId.toString());

          this.mainBlueprintReferenceFields = {
            ...this.mainBlueprintReferenceFields,
            [field._id]: reBlueprint?.fields ?? [],
          };
        }
      });
    },
    selectBindType(blueprintId, toFieldId) {
      const data = this.localEvent.copyData.find(item => item.blueprintId === blueprintId);
      if (data) {
        const field = data.fields.find(item => item.toFieldId === toFieldId);
        return field?.toType ?? 'from-field';
      }
      return 'from-field';
    },
    getUseHtml(blueprintId, toFieldId) {
      const data = this.localEvent.copyData.find(item => item.blueprintId === blueprintId);
      if (data) {
        const field = data.fields.find(item => item.toFieldId === toFieldId);
        return field?.useToHtml ?? false;
      }
      return false;
    },
    setUseHtml(blueprintId, toFieldId, event) {
      const data = this.localEvent.copyData.find(item => item.blueprintId === blueprintId);
      if (data) {
        const field = data.fields.find(item => item.toFieldId === toFieldId);
        if (field) {
          field.useToHtml = event.target.checked;
        } else {
          data.fields = [...data.fields, { toFieldId, useToHtml: event.target.checked }];
        }
      }
    },
    fromHtml(blueprintId, toFieldId) {
      const data = this.localEvent.copyData.find(item => item.blueprintId === blueprintId);
      if (data) {
        const field = data.fields.find(item => item.toFieldId === toFieldId);
        return field?.fromHtml ?? '';
      }
      return '';
    },
    setFromHtml(blueprintId, toFieldId, value) {
      const data = this.localEvent.copyData.find(item => item.blueprintId === blueprintId);
      if (data) {
        const field = data.fields.find(item => item.toFieldId === toFieldId);
        field.fromHtml = value;
      }
    },
    staticValue(blueprintId, toFieldId) {
      const data = this.localEvent.copyData.find(item => item.blueprintId === blueprintId);
      if (data) {
        const field = data.fields.find(item => item.toFieldId === toFieldId);
        return field?.toValue ?? '';
      }
      return '';
    },
    lastCopyFieldValue(blueprintId, toFieldId) {
      const data = this.localEvent.copyData.find(item => item.blueprintId === blueprintId);
      if (data.fields) {
        const fields = data.fields.find(item => item.toFieldId === toFieldId)?.fromFieldId ?? [];
        let lastField = fields[fields.length - 1];
        if (lastField?.type === 'reference') {
          const fl = this.blueprints
            .find(b => b._id.toString() === lastField.blueprintId.toString())
            ?.fields?.find(f => f._id.toString() === lastField.fieldId);
          lastField = { ...lastField, blueprintId: fl.structure.ruleset.blueprintId };
        }
        return lastField;
      }
      return null;
    },
    changeStaticValue(blueprintId, toFieldId, e) {
      const toType = { target: { value: 'static-value' } };
      const { value } = e.target;
      this.changeBindType(blueprintId, toFieldId, toType, value);
    },
    changeBindType(blueprintId, toFieldId, e, toValue = null) {
      const toType = e.target.value;

      const { copyData } = this.localEvent;
      const findCopyData = this.localEvent.copyData.find(item => item.blueprintId === blueprintId);
      if (!findCopyData) {
        copyData.push({
          blueprintId,
          fields: [
            {
              toFieldId,
              toType,
              toValue: toValue ?? '',
              toHtml: '',
              useToHtml: false,
              fromFieldId: [],
            },
          ],
        });
      } else {
        const field = findCopyData.fields.find(item => item.toFieldId === toFieldId);
        if (field) {
          // update
          findCopyData.fields = findCopyData.fields.map(item => {
            if (item.toFieldId === toFieldId) {
              return {
                toFieldId,
                toType,
                toValue: toValue ?? '',
                toHtml: '',
                useToHtml: false,
                fromFieldId: [],
              };
            }
            return item;
          });
        } else {
          // new
          findCopyData.fields.push({
            toFieldId,
            toType,
            toValue: toValue ?? '',
            toHtml: '',
            useToHtml: false,
            fromFieldId: [],
          });
        }
      }
    },
    selectField(blueprintId, toFieldId, value, index) {
      this.bindField = { ...this.bindField, [blueprintId]: toFieldId };

      const { copyData } = this.localEvent;
      const findCopyData = this.localEvent.copyData.find(item => item.blueprintId === blueprintId);
      if (!findCopyData) {
        copyData.push({
          blueprintId,
          fields: [
            {
              toFieldId,
              toType: 'from-field',
              toValue: null,
              toHtml: '',
              useToHtml: false,
              fromFieldId: [{ ...value, index }],
            },
          ],
        });
      } else {
        const field = findCopyData.fields.find(item => item.toFieldId === toFieldId);
        if (field && field.fromFieldId) {
          console.log(field);
          // update
          const referenceFields = field.fromFieldId.filter(item => item.index < index);
          if (value?.fieldId) {
            referenceFields.push({ ...value, index });
          }
          field.fromFieldId = referenceFields.sort((a, b) => a.index - b.index);
        } else {
          // new
          findCopyData.fields.push({
            toFieldId,
            toType: 'from-field',
            toValue: null,
            toHtml: '',
            useToHtml: false,
            fromFieldId: [{ ...value, index }],
          });
        }
      }
    },

    removeOne(blueprintId) {
      const el = this;
      this.$confirm({
        title: 'Confirm',
        content: 'Sure you want to delete?',
        okText: 'Yes',
        okType: 'danger',
        cancelText: 'Cancel',
        async onOk() {
          el.copyFromBlueprint = el.copyFromBlueprint.filter(item => item._id !== blueprintId);
          el.localEvent.copyData = el.localEvent.copyData.filter(item => item.blueprintId !== blueprintId);
        },
      });
    },

    async fetchAllBlueprint() {
      this.loadingBlueprints = true;
      this.blueprints = await blueprintApi.getAllWithFields();
      this.loadingBlueprints = false;
    },

    async selectedBlueprint(blueprintId) {
      if (!blueprintId) {
        this.selectedBlueprintValue = null;
        return;
      }
      const blueprint = this.blueprints.find(b => b._id.toString() === blueprintId.toString());
      if (!this.copyFromBlueprint.some(item => item._id === blueprint?._id)) {
        this.copyFromBlueprint.push({ ...blueprint });
      }
      message.success({ content: 'Blueprint added!', duration: 2 });
      this.selectedBlueprintValue = null;
    },

    async fetchBlueprint() {
      if (!this.selectedBlueprintId) {
        return;
      }
      const blueprint = this.blueprints.find(b => b._id.toString() === this.selectedBlueprintId.toString());
      this.fields = blueprint.fields;
      this.blueprint = blueprint;
    },
    filterOption(input, option) {
      return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    },
  },
};
</script>
<style scoped>
.update-fields {
  display: table;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 5px 10px;
  margin-bottom: 10px;
  width: 100%;
}
.update-fields:last-child {
  margin-bottom: 0px;
}
.update-fields .cell {
  display: table-cell;
  vertical-align: middle;
}
.input-name {
  width: 30%;
  border-right: 1px solid #ddd;
  height: 30px;
}
.modify-filed {
  width: 30%;
  padding-left: 10px;
}
.input-field {
  width: 40%;
  border-left: 1px solid #ddd;
  padding-left: 10px;
}
.mutted {
  font-size: 12px;
}
</style>
