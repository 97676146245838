<template>
  <div>
    <!--    {{field}}-->
    <reference-fields
      v-if="reloadEmpty"
      v-model="referenceFields"
      :parentReferenceFields="field ? fieldToRefereneceFields(field) : []"
      :key="referenceFieldsKey"
      :blueprints="blueprints"
      :selectedBlueprint="selectedBlueprint"
      :toFieldFromOtherBlueprint="toFieldFromOtherBlueprint ?? false"
      :justReferenceFields="justReferenceFields ?? true"
      :justReferenceAndIds="justReferenceAndIds"
      :autoFieldConditions="autoFieldConditions ?? true"
      :justSingleReferenceFields="true"
      :mainBlueprint="mainBlueprint"
      :cardView="false"
      :otherBlueprint="otherBlueprint ?? true"
      :hideIndex="hideIndex"
      :addTextToFirstSelector="addTextToFirstSelector"
      :forceShowId="forceShowId ?? false"
      :justReferencesAndFieldType="justReferencesAndFieldType ?? false"
      :reloadEmpty="true"
      :keyLimit="keyLimit ?? null"
      :noCondition="noCondition"
      @toField="selectField"
      @reloadField="reloadField"
      @updateField="updateField"
    />
    <!--            {{referenceFields[referenceFields.length-1]}}-->
  </div>
</template>
<script>
import ReferenceFields from '@/apps/templateManagement/views/Builder/components/referenceFieldsComponents/ReferenceFields.vue';
import { slugify } from '@/core/utils/string-manipulation';
import { objectId } from '@/core/utils/array-manipulation';

export default {
  name: 'ReferenceFieldManger',
  props: [
    'mainBlueprintId',
    'selectedBlueprint',
    'blueprints',
    'field',
    'toFieldFromOtherBlueprint',
    'justReferenceFields',
    'justReferenceAndIds',
    'autoFieldConditions',
    'hideIndex',
    'addTextToFirstSelector',
    'otherBlueprint',
    'forceShowId',
    'justReferencesAndFieldType',
    'keyLimit',
    'noCondition',
  ],
  emits: ['updateField', 'update:modelValue'],
  components: {
    ReferenceFields,
  },
  data() {
    return {
      modalConfigFieldFilters: false,
      modalConcatField: false,
      selectedField: null,
      selectedFieldFormatting: null,
      modalConfigFieldFormatting: false,
      referenceFields: [],
      referenceFieldsKey: 1,
      test: this.template,
      reloadEmpty: true,
    };
  },
  created() {},
  watch: {
    justReferencesAndFieldType: {
      // when field type changes, it`s remove last element
      deep: true,
      handler() {
        if (this.referenceFields[this.referenceFields.length - 1].type !== 'reference' && this.referenceFields?.length > 1) {
          this.reloadEmpty = false;
          this.referenceFields.pop();
          this.reloadField();
          setTimeout(() => {
            this.reloadEmpty = true;
          }, 500);
        }
      },
    },
  },
  methods: {
    slugify,
    reloadField() {
      this.addReferenceField();
    },
    updateField(value, index, mountedReference) {
      let fieldToUpdate = this.referenceFields.filter(item => item.index === index);
      /* eslint-disable-next-line no-unused-vars */
      fieldToUpdate = value;

      this.addNewFieldFromReferenceButton = this.referenceFields.length > 1 && !['array', 'reference'].includes(value.type);
      if (this.referenceFields.length && mountedReference) {
        this.addReferenceField();
      }
    },
    selectField(value, index, mountedReference) {
      const referenceFields = this.referenceFields.filter(item => item.index < index);

      if (value?.fieldId) {
        referenceFields.push({ ...value, index });
      }
      referenceFields.sort((a, b) => a.index - b.index);

      this.referenceFields = referenceFields;

      this.addNewFieldFromReferenceButton = this.referenceFields.length > 1 && !['array', 'reference'].includes(value.type);
      if (this.referenceFields.length && mountedReference) {
        this.addReferenceField();
      }
    },
    /**
     * Create field from multiple references
     * !!! At any reference field that changes value the ID of result field is changing
     */
    addReferenceField() {
      // create field
      let blueprintId = null;
      let blueprintName = '';
      let label;
      let type;
      let widgetType;
      let dateFormat;
      let typeDetails;
      const path = [];
      const pathDetails = [];
      let getFromInstance = null;
      const refIdPath = [];
      const oldRefIdPath = [];
      let fieldInput = null;
      let fieldStructure = null;
      let autoFieldConditionsData = [];
      let fieldConditionsDataAttributes = [];
      let widget = {};

      if (Array.isArray(this.referenceFields)) {
        this.referenceFields.forEach(item => {
          const blueprint = this.blueprints.find(b => b._id === item.blueprintId);
          if (blueprint) {
            blueprintId = blueprint._id;
            blueprintName = blueprint.name;
            getFromInstance = item.getFromInstance ?? null;
            autoFieldConditionsData = item?.autoFieldConditionsData ?? [];
            fieldConditionsDataAttributes = item?.fieldConditionsDataAttributes ?? [];

            if (item.fieldId.startsWith('other_')) {
              const fieldIdSplit = item.fieldId.replace('other_', '').split('-');
              const otherBlueprintId = fieldIdSplit[0];
              const otherFieldId = fieldIdSplit[1];

              const otherBlueprint = this.blueprints.find(b => b._id === otherBlueprintId);
              const otherField = otherBlueprint.fields.find(f => f._id === otherFieldId);
              const fieldRefBlueprintId = otherField.structure?.elementStructure?.ruleset?.blueprintId ?? otherField.structure.ruleset.blueprintId;

              widgetType = otherField.widget.type ? otherField.widget.type : '';
              refIdPath.push(`_reference_data_other_${otherBlueprint._id}-${fieldRefBlueprintId}-${otherFieldId}`);
              oldRefIdPath.push(`_reference_data_other_${otherBlueprintId}-${otherFieldId}`);

              pathDetails.push(`Other blueprint (${otherBlueprint.name}) -${item.type === 'array' ? ' [array]' : ' '}${otherField.label}`);
              path.push(`other-blueprint_${slugify(otherBlueprint.name)}-${slugify(otherField.label)}`);
            } else {
              const field = blueprint.fields.find(f => f._id === item.fieldId);
              if (field) {
                const fieldRefBlueprintId = field.structure?.elementStructure?.ruleset?.blueprintId ?? field.structure.ruleset.blueprintId;

                type = field?.structure?.elementStructure?.type ?? field?.structure?.type;
                widgetType = field.widget.type ? field.widget.type : '';
                dateFormat = field?.structure?.elementStructure?.options?.format ?? field?.structure?.options?.format ?? 'DD-MM-YYYY';

                typeDetails =
                  field.structure?.type === 'array'
                    ? `${field.structure.type}[${field?.structure?.elementStructure?.type}]`
                    : (field?.structure?.elementStructure?.type ?? field?.structure?.type);

                let prefix = '';
                if (item.type === 'reference') {
                  if (item.type === 'array') {
                    prefix = '[array] ';
                  } else {
                    prefix = ' ';
                  }
                }
                pathDetails.push(prefix + field.label);

                label = field.label;
                if (item.type === 'reference') {
                  refIdPath.push(`_reference_data_${!item.index ? `${item.blueprintId}-` : ''}${fieldRefBlueprintId}-${field._id}`);
                  oldRefIdPath.push(`_reference_data_${field._id}`);
                } else {
                  refIdPath.push(field._id);
                  oldRefIdPath.push(field._id);
                  fieldInput = field.input;
                  fieldStructure = field.structure;
                  widget = field.widget;
                }
              } else if (item.fieldId === '_createdAt' || item.fieldId === '_ownerId') {
                // if created at of owner
                label = item.fieldId === '_createdAt' ? 'Created At' : 'Owner';

                pathDetails.push(item.fieldId === '_createdAt' ? 'Created At' : 'Owner');
                path.push(item.fieldId === '_createdAt' ? 'created At' : 'owner');

                type = item.fieldId === '_createdAt' ? 'date' : 'string';
                typeDetails = item.fieldId === '_createdAt' ? 'date' : 'string';
                widgetType = item?.widget?.type ? item.widget.type : '';

                dateFormat = type === 'date' ? 'YYYY-MM-DD' : null;
                fieldStructure = { type, dateFormat };
                refIdPath.push(item.fieldId);
                oldRefIdPath.push(item.fieldId);
              } else if (item.fieldId === '_id') {
                label = item.fieldId;

                pathDetails.push(item.fieldId === '_createdAt' ? 'Created At' : 'Owner');
                path.push(item.fieldId === '_createdAt' ? 'created At' : 'owner');

                type = 'number';
                typeDetails = 'number';
                widgetType = item?.widget?.type ? item.widget.type : 'number';

                dateFormat = type === 'date' ? 'YYYY-MM-DD' : null;
                fieldStructure = { type, dateFormat };
                refIdPath.push(item.fieldId);
                oldRefIdPath.push(item.fieldId);
              }
              //
            }
          }
        });
      }

      let id;
      if (this.referenceFields.length > 1) {
        id = `${this.referenceFields[0].fieldId.startsWith('other_') ? '_other_blueprint_' : '_reference_'}${type}_${objectId()}`;
      } else {
        id = this.referenceFields[0].fieldId;
      }
      const acceptance = { validators: [] };

      const newField = {
        id,
        label,

        path: path.join('.'),
        pathDetails: pathDetails.join(' > '),
        widgetType,
        type,
        typeDetails,
        fromReference: true,
        getFromInstance: getFromInstance ?? null,

        refIdPath: refIdPath.join('.'),
        oldRefIdPath: refIdPath.join('.'),

        autoFieldConditionsData,
        fieldConditionsDataAttributes,

        show: true,
        dateFormat,
        input: fieldInput,
        widget,
        acceptance,
        structure: fieldStructure,
        inputRuleset: null,
        logic: {
          validation: { validators: [] },
          calculation: { isEnabled: false },
        },
        order: false,
        searchable: false,
        formatting: {
          toUppercase: false,
          count: false,
          distinct: true,
        },
        filter: false,
        filterValues: [],
        linkToReferenceInstance: false,

        deletable: true,
        mainBlueprintId: this.mainBlueprintId, // extract
        // on reference
        blueprintId,
        blueprintName,
      };

      this.$emit('updateField', newField);
      this.$emit('update:modelValue', newField);
    },
    /**
     * Split field to multiple reference
     * @param field  field that need split
     * @returns {[]|*[]} result of references
     */
    fieldToRefereneceFields(field) {
      let refFields = [];
      refFields = this.fieldToReferenceFields(field.refIdPath, field, field.blueprintId);
      return refFields;
    },
    /**
     * Recursive reference that create references
     * @param pathPart path of references
     * @param field  main field
     * @param parentBlueprintId used to link blueprint
     * @returns {*[]}
     */
    fieldToReferenceFields(pathPart, field, parentBlueprintId) {
      // create reference fieds
      let index = 0;
      let done = false;
      let refFields = [];
      if ((!pathPart.startsWith('_') && !done) || pathPart === '_createdAt' || pathPart === '_id') {
        const list = (pathPart ?? '').split('.');
        list.forEach(item => {
          const reference = {};
          reference.blueprintId = field.blueprintId; // main blueprint
          const fieldId = item;
          reference.fieldId = fieldId;
          reference.fieldConditionsDataAttributes = field.fieldConditionsDataAttributes ?? [];
          reference.autoFieldConditionsData = field.autoFieldConditionsData ?? [];

          const blueprint = this.blueprints.find(b => b._id.toString() === field.blueprintId);
          const searchedField = blueprint.fields.find(f => f._id.toString() === fieldId);
          if (searchedField) {
            reference.fieldName = searchedField.label;
            reference.type = searchedField.structure.type;
            reference.index = index;
            refFields.push(reference);
            index += 1;
          }
        });

        if (pathPart === '_createdAt') {
          const reference = {};
          reference.blueprintId = field.blueprintId; // main blueprint
          reference.fieldId = field.id;
          reference.fieldConditionsDataAttributes = field.fieldConditionsDataAttributes ?? [];
          reference.autoFieldConditionsData = field.autoFieldConditionsData ?? [];
          reference.fieldName = field.label;
          reference.type = 'date';
          reference.index = index;
          refFields.push(reference);
          index += 1;
        }
        if (pathPart === '_id') {
          const reference = {};
          reference.blueprintId = field.blueprintId; // main blueprint
          reference.fieldId = field.id;
          reference.fieldConditionsDataAttributes = field.fieldConditionsDataAttributes ?? [];
          reference.autoFieldConditionsData = field.autoFieldConditionsData ?? [];
          reference.fieldName = field.label;
          reference.type = 'number';
          reference.index = index;
          refFields.push(reference);
          index += 1;
        }
        done = true;
      }
      if (pathPart.startsWith('_reference_data_other_') && !done) {
        const reference = {};
        const fieldOfReference = {};
        const splitByPoint = pathPart.split('.');
        const splitedRef = splitByPoint[0].replace('_reference_data_other_', '').split('-');
        const otherBlueprintID = splitedRef[0].replace('other_', '');
        const blueprintId = splitedRef[1];
        const refField = splitedRef[2];
        splitByPoint.shift();
        const fieldId = splitByPoint.length ? splitByPoint.join('.') : null;
        reference.blueprintId = blueprintId; // main blueprint
        reference.fieldId = `other_${otherBlueprintID}-${refField}`;
        reference.fieldName = `other_${otherBlueprintID}-${refField}`;
        reference.fieldConditionsDataAttributes = field.fieldConditionsDataAttributes ?? [];
        fieldOfReference.autoFieldConditionsData = field.autoFieldConditionsData ?? [];
        reference.type = 'reference';
        reference.index = index;
        index += 1;
        refFields.push(reference);

        if (fieldId) {
          if (!fieldId.startsWith('_reference_data_')) {
            const blueprint = this.blueprints.find(b => b._id.toString() === otherBlueprintID);

            const searchedField = blueprint.fields.find(f => f._id.toString() === fieldId);
            fieldOfReference.blueprintId = otherBlueprintID; // main blueprint
            fieldOfReference.fieldId = fieldId;
            fieldOfReference.fieldName = searchedField.label;
            fieldOfReference.fieldConditionsDataAttributes = field.fieldConditionsDataAttributes ?? [];
            fieldOfReference.autoFieldConditionsData = field.autoFieldConditionsData ?? [];
            fieldOfReference.type = searchedField.structure.type;
            reference.index = index;
            refFields.push(fieldOfReference);
            index += 1;
          } else {
            refFields = refFields.concat(this.fieldToReferenceFields(fieldId, field, otherBlueprintID));
          }
        }
        done = true;
      }
      if (pathPart.startsWith('_reference_data_') && !done) {
        const reference = {};
        const fieldOfReference = {};
        const splitByPoint = pathPart.split('.');
        const splitedRef = splitByPoint[0].replace('_reference_data_', '').split('-');

        let otherBlueprintId = '';
        let blueprintId = '';
        let refField = '';
        let fieldId = '';
        if (splitedRef.length === 3) {
          [blueprintId, otherBlueprintId, refField] = splitedRef;
          splitByPoint.shift();
          fieldId = splitByPoint.join('.');
        } else {
          blueprintId = parentBlueprintId;
          [otherBlueprintId, refField] = splitedRef;
          splitByPoint.shift();
          fieldId = splitByPoint.join('.');
        }
        if (!blueprintId) {
          return [];
        }
        const blueprint = this.blueprints.find(b => b._id.toString() === blueprintId);
        const searchedField = blueprint.fields.find(f => f._id.toString() === refField);
        reference.blueprintId = blueprintId; // main blueprint
        reference.fieldId = refField;
        reference.fieldName = searchedField.label;
        reference.fieldConditionsDataAttributes = field.fieldConditionsDataAttributes ?? [];
        reference.autoFieldConditionsData = field.autoFieldConditionsData ?? [];
        reference.type = 'reference';
        reference.index = index;
        index += 1;
        refFields.push(reference);
        if (fieldId) {
          if (!fieldId.startsWith('_reference_data_')) {
            const bp = this.blueprints.find(b => b._id.toString() === otherBlueprintId);

            const searchedF = bp.fields.find(f => f._id.toString() === fieldId);
            if (searchedF) {
              fieldOfReference.fieldName = searchedF.label;
              fieldOfReference.type = searchedF.structure.type;
            } else {
              fieldOfReference.fieldName = fieldId;
              fieldOfReference.type = 'date';
            }
            fieldOfReference.blueprintId = otherBlueprintId;

            fieldOfReference.fieldId = fieldId;
            fieldOfReference.fieldConditionsDataAttributes = field.fieldConditionsDataAttributes ?? [];
            fieldOfReference.autoFieldConditionsData = field.autoFieldConditionsData ?? [];

            fieldOfReference.index = index;
            refFields.push(fieldOfReference);
            index += 1;
          } else {
            refFields = refFields.concat(this.fieldToReferenceFields(fieldId, field, otherBlueprintId));
          }
        }

        done = true;
      }
      return refFields;
    },
  },
  computed: {
    mainBlueprint() {
      return this.blueprints.find(bp => bp._id === this.mainBlueprintId);
    },
  },
};
</script>
