<template>
  <div>
    <div class="details-container">
      <div class="content section" :class="sourceSection === 1 ? 'active' : ''" @click="selectSection">
        <div>
          <CaretDownOutlined v-if="sourceSection === 1" />
          <CaretUpOutlined v-else />
          <strong>1. Configure connection</strong>
          <CheckCircleOutlined class="validate-section" v-if="localSource.connectionDetail" />
        </div>
      </div>
    </div>
    <template v-if="sourceSection === 1">
      <div class="details-container">
        <div class="content">
          <div style="padding-bottom: 15px">
            <a-radio-group v-model:value="localSource.connectionType" name="Trigger Type">
              <a-radio disabled :value="'HTTP_SUBSCRIPTION'"> REST Hook </a-radio>
              <a-radio :value="'HTTP_POLLING'">Polling</a-radio>
              <a-radio disabled :value="'SFTP_POLLING'">SFTP</a-radio>
              <a-radio disabled :value="'BLUEPRINT'">Blueprint</a-radio>
            </a-radio-group>
          </div>

          <div style="padding-bottom: 15px">
            Does this endpoint always return new data?
            <a-switch
              v-model:checked="localSource.dataAlwaysNew"
              @change="useDataAlwaysNew"
              :loading="preloaderDataAlwaysNew"
              checked-children="Yes"
              un-checked-children="No"
            />
          </div>

          <template v-if="localSource.dataAlwaysNew === false">
            <div style="padding-bottom: 15px">
              <a-tooltip
                title="On each request only the new data will be inserted. But we will receive data that was already saved, which can have
              updated values. How do you want to handle this scenario?"
              >
                Do you want to update the existing data of the Source?
              </a-tooltip>
              <a-switch
                v-model:checked="localSource.entitiesWillUpdate"
                @change="useEntitiesWillUpdate"
                :loading="preloaderEntitiesWillUpdate"
                checked-children="Yes, update existing data"
                un-checked-children="No, do not update any existing data"
              />
            </div>
          </template>

          <template v-if="localSource.connectionType === 'HTTP_SUBSCRIPTION'">
            <a-button-group>
              <a-button :class="sourceHookTab === 1 ? 'grey' : ''" @click="sourceHookTab = 1"> Subscription </a-button>
              <a-button :class="sourceHookTab === 2 ? 'grey' : ''" @click="sourceHookTab = 2"> Cancellation </a-button>
              <a-button :class="sourceHookTab === 3 ? 'grey' : ''" @click="sourceHookTab = 3"> Sampling </a-button>
            </a-button-group>
            <div style="margin-top: -5px">
              <div class="saas_card padd-10" v-show="sourceHookTab === 1">
                <h4>Subscription details:</h4>
                <SubscriptionForm
                  v-model="localSource.httpSubscription"
                  :type="localSource.connectionType"
                  :selectedDataProvider="selectedDataProvider"
                />
              </div>
              <div class="saas_card padd-10" v-show="sourceHookTab === 2">
                <h4>Cancellation details:</h4>
                <SubscriptionForm
                  v-model="localSource.httpCancellation"
                  :type="localSource.connectionType"
                  :selectedDataProvider="selectedDataProvider"
                />
              </div>
              <div class="saas_card padd-10" v-show="sourceHookTab === 3">
                <h4>Sampling data details:</h4>
                <SubscriptionForm
                  v-model="localSource.httpSampling"
                  :type="localSource.connectionType"
                  :selectedDataProvider="selectedDataProvider"
                />
              </div>
            </div>
          </template>
          <template v-else-if="localSource.connectionType === 'HTTP_POLLING'">
            <SubscriptionForm v-model="localSource.httpPolling" :type="localSource.connectionType" :selectedDataProvider="selectedDataProvider" />

            <ScheduleComponent v-if="this.selectedDataProvider.type === 'auto'" v-model="localSource.recurrence" />
          </template>
          <template v-else-if="localSource.connectionType === 'SFTP_POLLING'">
            <FtpForm v-model="localSource.sftpPolling" :type="localSource.connectionType" />
          </template>
          <template v-else-if="localSource.connectionType === 'BLUEPRINT'">
            {{ localSource.blueprint }}
            <BlueprintComponent v-model="localSource.blueprint" :type="localSource.connectionType" />
          </template>
        </div>

        <div style="padding: 10px" align="left" v-if="localSource.connectionDetail && localSource.connectionDetail.success">
          <a-tooltip
            :mouseLeaveDelay="0"
            title="This will reset the current configuration (source fields and any Mapper using this Dataflow will need to be configured again)"
          >
            <a-button type="primary" :loading="preloaderUpdate" @click="updateSource">
              <EditOutlined />
              RETEST
              <span v-if="localSource.connectionType !== 'BLUEPRINT'" style="padding-left: 5px"> CONNECTION </span>
            </a-button>
          </a-tooltip>
          <a-button v-if="this.selectedDataProvider.type === 'auto'" type="primary" class="ml-2" @click="saveSourceChanges"
            >SAVE CRON CHANGES</a-button
          >
        </div>
        <div v-else style="padding: 10px" align="left">
          <a-button type="primary" @click="addSource">
            <SaveOutlined /> SAVE
            <span v-if="localSource.connectionType !== 'BLUEPRINT'" style="padding-left: 5px"> & TEST CONNECTION </span>
          </a-button>
        </div>
        <div style="padding: 10px" v-if="Object.keys(error).length">
          <a-alert :message="error.message" :description="error.catchedMessage" type="error" show-icon />
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { DataProviderActions } from '@dataProvider/shared/dataProvider.store';
import { CaretDownOutlined, CaretUpOutlined, CheckCircleOutlined, EditOutlined, SaveOutlined } from '@ant-design/icons-vue';
import { notification } from 'ant-design-vue';
import SubscriptionForm from '../../forms/subscription/subscription.vue';
import ScheduleComponent from '../../forms/schedule/schedule.vue';
import FtpForm from '../../forms/ftp/ftp.vue';
import BlueprintComponent from '../../forms/blueprint/blueprint.vue';

export default {
  props: ['sourceSection', 'dataProviderId', 'sourceId', 'source', 'error', 'preloaderUpdate', 'selectedDataProvider'],
  emits: ['addSource', 'editSource', 'ch-section'],
  data() {
    return {
      loading: false,
      sourceHookTab: 1,
      preloaderDataAlwaysNew: false,
      preloaderEntitiesWillUpdate: false,
      localSource: this.source ?? null,
    };
  },
  mounted() {
    this.localSource = this.source ?? null;
  },
  watch: {
    localSource: {
      deep: true,
      handler(newValue, oldValue) {
        if (newValue?.connectionType !== oldValue?.connectionType) {
          this.resetConnectionType(newValue);
        }
        // this.updateSource();
      },
    },
  },

  methods: {
    saveSourceChanges() {
      this.$emit('saveSourceChanges', this.localSource);
      notification.open({
        message: 'CRON',
        description: 'Cron expression updated.',
        duration: 1,
      });
    },
    async useDataAlwaysNew(status) {
      this.preloaderDataAlwaysNew = true;
      const updateStatus = !!status;
      let updateData = {
        dataAlwaysNew: updateStatus,
      };
      if (!status) {
        updateData = {
          primaryFieldId: null,
          dataAlwaysNew: updateStatus,
        };
      }
      if (this.sourceId !== 'new') {
        await DataProviderActions.updateOneSourceSafely(this.dataProviderId, this.sourceId, updateData);
        if (!status) {
          this.localSource.primaryFieldId = null;
        }
      }
      this.localSource.dataAlwaysNew = updateStatus;
      this.preloaderDataAlwaysNew = false;
    },
    async useEntitiesWillUpdate(status) {
      this.preloaderEntitiesWillUpdate = true;
      const updateStatus = !!status;
      const updateData = {
        entitiesWillUpdate: updateStatus,
      };
      if (this.sourceId !== 'new') {
        await DataProviderActions.updateOneSourceSafely(this.dataProviderId, this.sourceId, updateData);
        if (!status) {
          this.localSource.primaryFieldId = null;
        }
      }
      this.localSource.entitiesWillUpdate = updateStatus;
      this.preloaderEntitiesWillUpdate = false;
    },
    addSource() {
      this.$emit('addSource', this.localSource);
    },
    updateSource() {
      this.$emit('editSource', this.localSource);
    },
    resetConnectionType(type) {
      if (type === 'HTTP_SUBSCRIPTION') {
        this.$delete(this.localSource, 'httpPolling');
        this.$delete(this.localSource, 'sftpPolling');
        this.$delete(this.localSource, 'recurrence');
      } else if (type === 'HTTP_POLLING') {
        this.$delete(this.localSource, 'httpSubscription');
        this.$delete(this.localSource, 'httpCancellation');
        this.$delete(this.localSource, 'httpSampling');
        this.$delete(this.localSource, 'sftpPolling');
      } else if (type === 'SFTP_POLLING') {
        this.$delete(this.localSource, 'httpSubscription');
        this.$delete(this.localSource, 'httpCancellation');
        this.$delete(this.localSource, 'httpSampling');
        this.$delete(this.localSource, 'httpPolling');
        this.$delete(this.localSource, 'recurrence');
      }
    },
    changeComponent(component, data) {
      this.localSource[component] = data;
    },
    selectSection() {
      this.$emit('ch-section', 1);
    },
  },
  components: {
    SubscriptionForm,
    ScheduleComponent,
    FtpForm,
    BlueprintComponent,
    CaretDownOutlined,
    CaretUpOutlined,
    CheckCircleOutlined,
    EditOutlined,
    SaveOutlined,
  },
};
</script>
<style scoped>
.section {
  position: relative;
  cursor: pointer;
}
.section:hover {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.09);
}
.section.active {
  background-color: whitesmoke;
}
.section .validate-section {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  font-size: 18px;
  color: #1aa967;
}
</style>
