<template>
  <div>
    <a-modal :width="'900px'" :footer="false" title="Available Tokens" :open="visible" :destroyOnClose="true" @cancel="$emit('close')">
      <a-tabs default-active-key="1">
        <a-tab-pane key="1" tab="Tokens">
          <a-tabs :tab-position="'left'" default-active-key="1">
            <a-tab-pane key="1" tab="User">
              <div class="row-custom" v-if="user">
                <div class="item">
                  <template v-for="token in Object.keys(objectToDot({ user: user }))" :key="token">
                    <a-button size="small" @click="copyURL(simpleTokenUrl(token))" style="margin: 0 5px 5px 0">
                      {{ simpleTokenUrl(token) }}
                      <CopyOutlined />
                    </a-button>
                  </template>
                </div>
              </div>
            </a-tab-pane>
            <a-tab-pane key="2" tab="Tenant">
              <div class="row-custom" v-if="globalTokens['tenant']">
                <div class="item">
                  <template v-for="token in Object.keys(objectToDot(globalTokens['tenant']))" :key="token">
                    <a-button size="small" @click="copyURL(simpleTokenUrl(token))" style="margin: 0 5px 5px 0">
                      {{ simpleTokenUrl(token) }}
                      <CopyOutlined />
                    </a-button>
                  </template>
                </div>
              </div>
            </a-tab-pane>
            <a-tab-pane key="3" tab="App">
              <div class="row-custom" v-if="globalTokens['app']">
                <div class="item">
                  <template v-for="token in Object.keys(objectToDot(globalTokens['app']))" :key="token">
                    <a-button size="small" @click="copyURL(simpleTokenUrl(token))" style="margin: 0 5px 5px 0">
                      {{ simpleTokenUrl(token) }}
                      <CopyOutlined />
                    </a-button>
                  </template>
                </div>
              </div>
            </a-tab-pane>
            <a-tab-pane key="4" tab="Subtenants">
              <div class="row-custom" v-if="globalTokens['subtenants']">
                <div class="item">
                  <template v-for="token in Object.keys(objectToDot(globalTokens['subtenants']))" :key="token">
                    <a-button size="small" @click="copyURL(simpleTokenUrl(token))" style="margin: 0 5px 5px 0">
                      {{ simpleTokenUrl(token) }}
                      <CopyOutlined />
                    </a-button>
                  </template>
                  <div v-for="subtenantName in globalTokens['subtenantsListNames']" v-bind:key="subtenantName">
                    <template v-if="globalTokens['subtenantsList'][subtenantName]">
                      <h5 style="padding-left: 20px">{{ subtenantName }}</h5>
                      <template v-for="token in Object.keys(objectToDot(globalTokens['subtenantsList'][subtenantName]))" :key="token">
                        <a-button size="small" @click="copyURL(simpleTokenUrl(token))" style="margin: 0 5px 5px 0">
                          {{ simpleTokenUrl(token) }}
                          <CopyOutlined />
                        </a-button>
                      </template>
                    </template>
                  </div>
                </div>
              </div>
            </a-tab-pane>
            <a-tab-pane key="5" tab="Blueprint">
              <div class="row-custom">
                <div class="item">
                  <h4>{{ blueprint?.name }}</h4>
                  <div>
                    <template v-for="field in fields" :key="field._id">
                      <template v-if="field.structure.type !== 'reference'">
                        <a-button size="small" @click="copyURL(generateToken(field))" style="margin: 0 5px 5px 0">
                          {{ generateToken(field) }}
                          <CopyOutlined />
                        </a-button>
                      </template>
                      <template v-else>
                        <template
                          v-for="refField in fieldsByBlueprint[field.structure?.ruleset?.blueprintId]"
                          :key="refField.label + field._id.toString()"
                        >
                          <a-button
                            size="small"
                            @click="copyURL(generateTokenReference(field, refField))"
                            style="margin: 0 5px 5px 0"
                            v-if="refField?.structure?.type !== 'reference'"
                          >
                            {{ generateTokenReference(field, refField) }}
                            <CopyOutlined />
                          </a-button>
                        </template>
                      </template>
                    </template>

                    <template v-if="ownerDetailsExist">
                      <template v-for="token in userTokens" :key="token">
                        <a-button size="small" @click="copyURL(generateCustomToken(token, 'owner'))" style="margin: 0 5px 5px 0">
                          {{ generateCustomToken(token, 'owner') }}
                          <CopyOutlined />
                        </a-button>
                      </template>
                    </template>

                    <a-button size="small" @click="copyURL(generateCustomToken('_created_at'))" style="margin: 0 5px 5px 0">
                      {{ generateCustomToken('_created_at') }}
                      <CopyOutlined />
                    </a-button>
                  </div>
                </div>
              </div>
            </a-tab-pane>
            <a-tab-pane key="7" tab="External Auth">
              <pre>{{ examplesAuthRestData() }}</pre>
            </a-tab-pane>
            <a-tab-pane key="6" tab="Custom">
              <div class="row-custom">
                <div class="item" v-if="customTokens && customTokens.length">
                  <div>
                    <template v-for="custom in customTokens" :key="custom">
                      <a-button size="small" @click="copyURL(generateNormalToken(custom))" style="margin: 0 5px 5px 0">
                        {{ generateNormalToken(custom) }}
                        <CopyOutlined />
                      </a-button>
                    </template>
                  </div>
                </div>
              </div>
            </a-tab-pane>
          </a-tabs>
        </a-tab-pane>
        <a-tab-pane key="2" tab="Delimiters" v-if="!disableDotJs">
          <div>
            <h5>Here is the list of default delimiters:</h5>

            <pre
              v-html="
                `{{ }}	  for evaluation
{{= }}	  for interpolation
{{! }}	  for interpolation with encoding
{{# }}	  for compile-time evaluation/includes and partials
{{## #}}  for compile-time defines
{{? }}	  for conditionals
{{~ }}	  for array iteration`
              "
            ></pre>
          </div>
        </a-tab-pane>
        <a-tab-pane key="3" tab="Examples" v-if="!disableDotJs">
          <pre>{{ examples() }}</pre>
        </a-tab-pane>
      </a-tabs>
    </a-modal>
  </div>
</template>

<script>
import { blueprintApi, instanceApi } from '@dataSystem/api';
import { UserGetters } from '@userManagement/user.store';
import { TenantsGetters } from '@tenants/shared/tenants.store';
import { serializeSubtenant } from '@tenants/serializers/serializer';
import { CopyOutlined } from '@ant-design/icons-vue';
import dataAuthRestApi from '@/apps/dataAuthRest/api/dataAuthRest.api';
import { slugify } from '../utils/string-manipulation';

const dot = require('dot-object');

export default {
  props: ['visible', 'blueprintId', 'customTokens', 'ownerDetailsExist', 'disableDotJs'],
  components: {
    CopyOutlined,
  },
  async created() {
    await this.getGlobalTokens();
    await this.fetchBlueprint();
    await this.fetchAuthRestData();
  },
  data() {
    return {
      blueprint: null,
      fields: [],
      fieldsByBlueprint: {},
      globalTokens: {},
      userTokens: ['user.id', 'user.email', 'user.firstName', 'user.lastName'],
      authRestData: [],
    };
  },
  computed: {
    user: UserGetters.getUser,
  },
  methods: {
    examples() {
      return `
      <a-button type="primary" @click="trigger({ open: 'inPopup'})">Modifica</a-button>
      <a-button type="primary" @click="goToTemplate('templateId')">GO TO</a-button>
      <a-button type="primary" @click="generatePDF()">Generate PDF</a-button>

      {{ if (token.${slugify(this.blueprint?.name)} && token.${slugify(this.blueprint?.name)}.list) { }}
      <div>
        <div>Total: {{=token.${slugify(this.blueprint?.name)}.totalCount }}</div>
        {{ for (const data of token.${slugify(this.blueprint?.name)}.list) { }}
          <div class="card mb-2">
           <div class="card-body">
             <h5 class="card-title">{{=data.descriere }}</h5>
             <h6 class="card-subtitle mb-2 text-muted">{{=data.tip_finalizare??'' }}</h6>
             <a-button @click="trigger('{{!JSON.stringify(data)}}')">Detalii</a-button>
           </div>
          </div>
        {{ } }}
      </div>
      {{ } }}
      `;
    },
    examplesAuthRestData() {
      return (this.authRestData ?? [])
        .map(item => `<a-button type="primary" @click="extAuthRest('${item._id}')">\n  ${item.name}\n</a-button>`)
        .join('\n\n');
    },
    objectToDot(object) {
      return dot.dot(object);
    },
    simpleTokenUrl(token) {
      return this.addTokenValidation(`{{=token.${token}}}`);
    },
    generateNormalToken(filedName) {
      return this.addTokenValidation(`{{=${slugify(filedName)}}}`);
    },
    generateCustomToken(filedName, section = null) {
      // return this.addTokenValidation('{{=token.' + slugify(this.blueprint?.name) + (section ? '.' + section + '.' : '.') + slugify(filedName) + '}}');
      return this.addTokenValidation(`{{=token.${slugify(this.blueprint?.name)}${section ? `.${section}.` : '.'}${slugify(filedName)}}}`);
    },
    generateToken(field) {
      return this.addTokenValidation(`{{=token.${slugify(this.blueprint?.name)}.${slugify(field.label)}}}`);
    },
    generateTokenReference(field, refField) {
      return this.addTokenValidation(`{{=token.${slugify(this.blueprint?.name)}.${slugify(field.label)}.${slugify(refField.label)}}}`);
    },
    addTokenValidation(str) {
      return str.replaceAll('.', '?.');
    },
    async fetchAuthRestData() {
      const authRestData = await dataAuthRestApi.getAllWithChainedRequests({});
      this.authRestData = authRestData;
    },
    async fetchBlueprint() {
      if (this.blueprintId) {
        const { blueprint, fieldsById } = await blueprintApi.getOne(this.blueprintId);
        this.blueprint = blueprint;
        this.fields = fieldsById;
        /* for (const field in this.fields) {
          if (this.fields[field].structure.type === 'reference') {
            await this.getReferenceFields(
              this.fields[field].structure.ruleset.blueprintId
            );
          }
        } */
        const promises = Object.values(this.fields).map(async field => {
          if (field.structure.type === 'reference') {
            await this.getReferenceFields(field.structure.ruleset.blueprintId);
          }
        });
        await Promise.all(promises);
      }
    },
    async getReferenceFields(blueprintId) {
      if (!this.fieldsByBlueprint[blueprintId]) {
        const { fieldsById } = await blueprintApi.getOne(blueprintId);
        this.fieldsByBlueprint[blueprintId] = fieldsById;
      }
    },
    copyURL(token) {
      navigator.clipboard.writeText(token);
    },
    async getGlobalTokens() {
      const subtenants = await TenantsGetters.getAllSubtenants();
      const subtenantsList = {};
      const subtenantsListNames = [];

      const promises = Object.values(subtenants).map(async subtenant => {
        const copySubtenant = { ...subtenant };
        subtenantsListNames.push(copySubtenant.name);
        this.globalTokens.subtenants = {
          global: { subtenant: serializeSubtenant(copySubtenant) },
        };
        if (subtenant.blueprintId) {
          const instanceData = await instanceApi.getSubtenantData(subtenant._id, subtenant.slug);
          if (Object.keys(instanceData).length) {
            subtenantsList[copySubtenant.name] = {
              global: {
                subtenant: {
                  data: await instanceApi.getSubtenantData(subtenant._id, subtenant.slug),
                },
              },
            };
          }
        }
      });
      await Promise.all(promises);

      const tenantAppSubtenant = TenantsGetters.getTenantAppSubtenant();
      this.globalTokens.tenant = {
        global: { tenant: tenantAppSubtenant.tenant },
      };
      this.globalTokens.app = { global: { app: tenantAppSubtenant.app } };
      this.globalTokens.subtenantsList = subtenantsList;
      this.globalTokens.subtenantsListNames = subtenantsListNames;
    },
  },
};
</script>
<style scoped>
pre {
  font-size: 12px;
  border: 2px solid grey;

  border-left: 12px solid green;
  border-radius: 5px;
  padding: 14px;

  /* Fixed line height */
  line-height: 24px;

  /* Use linear-gradient for background image */
  background-image: linear-gradient(180deg, #eee 50%, #fff 50%);

  /* Size background so that the height is 2x line-height */
  background-size: 100% 48px;

  /* Offset the background along the y-axis by top padding */
  background-position: 0 14px;
}
</style>
